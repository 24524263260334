<template>
  <AuthModalContainer
    :title="titleText"
    @close="handleCloseModal"
  >
    <div class="header">
      <div
        v-if="attemptsLeft > 0"
        class="subtitle"
      >
        Отправили его на почту <span class="highlight">{{ email }}</span
        >.

        <span v-if="resendsLeft !== null && resendsLeft > 0">
          {{ resendsLeftText.preposition }}
          <span class="highlight">{{ resendsLeftText.text }}</span> отправки кода</span
        >

        <span v-else-if="resendsLeft !== null && resendsLeft === 0">
          Это последний код — запросить новый можно будет через 24 часа.
        </span>
      </div>

      <div
        v-else
        class="subtitle"
      >
        Попробуйте запросить код снова через 24 часа. Если у вас возникли вопросы или сложности,
        напишите нам: <a href="mailto:help@lifehacker.ru">help@lifehacker.ru</a>
      </div>
    </div>

    <AuthWarning v-if="isUnknownError"> Не удалось войти в аккаунт. Попробуйте снова. </AuthWarning>

    <div
      v-if="attemptsLeft"
      class="content"
    >
      <div class="code-input">
        <input
          v-for="(_, index) in code"
          :key="index"
          ref="numberInputs"
          type="number"
          inputmode="digital"
          class="digit-input"
          :class="{ 'digit-input--error': inputError[index] }"
          :value="code[index]"
          @paste="handlePaste"
          @keydown.backspace="(event) => handleBackspace(event, index)"
          @input.prevent="(event) => handleInput(event as InputEvent, index)"
        />
      </div>

      <div
        v-if="isCodeWrong"
        class="error"
      >
        Неверный код. Скопируйте его из письма.
      </div>

      <div class="actions">
        <button
          class="submit-button"
          :disabled="isVerifyButtonDisabled"
          @click="verifyCode"
        >
          Продолжить
        </button>

        <button
          class="resend-button"
          :class="{ 'resend-button--success': isCodeResent }"
          :disabled="resendsLeft === 0 || isCodeResent"
          @click="resendCode"
        >
          <div
            v-if="resendCodeLoading"
            class="loader"
          />
          <span v-else> {{ resendCodeButtonText }}</span>
        </button>
      </div>
    </div>
  </AuthModalContainer>
</template>

<script setup lang="ts">
  import { useModal } from '@devhacker/shared/plugins/modal/useModal';
  import { ref, nextTick, type PropType } from 'vue';
  import type {
    CheckCodeError,
    CheckCodeResponse,
    RequestCodeData,
    RequestCodeResponse,
  } from '~/types/api/auth';
  import { useIndexStore } from '~/store';
  import { setAccessToken } from '~/utils';
  import { pluralRu } from '@devhacker/shared/utils/pluralRu';
  import AuthModalContainer from '~/components/Auth/AuthModalContainer.vue';
  import ProfileSetup from './ProfileSetup.vue';
  import AuthWarning from '~/components/Auth/AuthWarning.vue';

  const props = defineProps({
    email: {
      type: String as PropType<string>,
      required: true,
    },
  });

  const emits = defineEmits(['close']);

  onMounted(() => {
    useNuxtApp().$sendYandexMetrika({ level1: 'Авторизация_попап_показ_ввод кода' });
    resetCode(true);
  });

  // Reactive state for the 6-digit code
  const code = ref(Array(6).fill(''));
  const codeLength = computed(() => code.value.join('').length);

  const isUnknownError = ref(false);
  const isVerifyPending = ref(false);
  const isVerifyButtonDisabled = computed(
    () => isVerifyPending.value || codeLength.value !== code.value.length,
  );

  // Input refs
  const numberInputs = ref<HTMLInputElement[]>([]);

  // Code data
  const resendsLeft = ref<number | null>(null);
  const attemptsLeft = ref(5);

  const titleText = computed(() =>
    attemptsLeft.value > 0 ? 'Введите код из письма' : 'На сегодня попытки закончились',
  );

  const resendsLeftText = computed(() => ({
    preposition: pluralRu(resendsLeft.value ?? 0, 'осталась', 'осталось'),
    text: `${resendsLeft.value} ${pluralRu(resendsLeft.value ?? 0, 'попытка', 'попытки', 'попыток')}`,
  }));

  // Analytics
  const sendAnalytics = (eventName: string) => {
    useNuxtApp().$sendYandexMetrika({
      level1: eventName,
    });
  };

  // Code resend
  const resendCodeLoading = ref(false);
  const isCodeResent = ref(false);
  const isCodeWrong = ref(false);

  const resendCodeButtonText = computed(() =>
    isCodeResent.value ? 'Код отправлен' : 'Отправить код ещё раз',
  );

  const resetCode = (focusAfter: boolean = false) => {
    for (let i = 0; i < code.value.length; i++) {
      // Reset current code
      code.value[i] = '';
    }

    focusAfter &&
      nextTick(() => {
        numberInputs.value[0].focus();
      });
  };

  const resendCode = async () => {
    sendAnalytics('Авторизация_попап_повторная_отправка_кода');

    isUnknownError.value = false;
    resendCodeLoading.value = true;
    resetCode();

    const { data, error } = await useAsyncData<RequestCodeResponse, RequestCodeData>(async () =>
      useNuxtApp().$authApi.requestCode(props.email ?? ''),
    );

    if (error.value) {
      attemptsLeft.value = error.value.data?.data?.attempts_left ?? 0;
      resendsLeft.value = error.value.data?.data?.resends_left ?? 0;

      if (!error.value.data) isUnknownError.value = true;
    }

    resendCodeLoading.value = false;

    if (data.value?.status === 200) {
      attemptsLeft.value = data.value.data.data.attempts_left;
      resendsLeft.value = data.value.data.data.resends_left;

      isCodeResent.value = true;
      isCodeWrong.value = false;

      nextTick(() => {
        numberInputs.value[0].focus();
      });

      setTimeout(() => {
        isCodeResent.value = false;
      }, 2000);
    }
  };

  // Verify code
  const verifyCode = async () => {
    isVerifyPending.value = true;
    isUnknownError.value = false;

    sendAnalytics('Авторизация_попап_клик_продолжить');

    const codeString = code.value.join('');

    const { data, error } = await useAsyncData<CheckCodeResponse, CheckCodeError>(() =>
      useNuxtApp().$authApi.checkCode(props.email, codeString),
    );

    isVerifyPending.value = false;

    if (error.value) {
      isCodeWrong.value = error.value.statusCode === 401;

      if (isCodeWrong.value) {
        attemptsLeft.value = error.value.data?.data?.code?.attempts_left ?? 0;
        resetCode(true);

        if (attemptsLeft.value === 0) {
          sendAnalytics('Авторизация_попап_окончание_попыток_ввода');
        }
      } else {
        isUnknownError.value = true;
      }

      return;
    }

    if (data.value?.status === 200) {
      setAccessToken(data.value.data.data.token.access_token);

      const user = data.value.data.data.user;
      useIndexStore().updateUser(user);

      if (user.is_user_created) {
        useModal().open(ProfileSetup, {
          userId: user.integer_id,
          email: user.email,
          name: user.display_name,
        });
      } else {
        useModal().close();
      }
    }
  };

  // Input handling
  const handleBackspace = (event: KeyboardEvent, index: number) => {
    event.preventDefault();

    if (code.value[index]) {
      code.value[index] = '';
      (event.target as HTMLInputElement).value = '';
    } else if (index > 0) {
      code.value[index - 1] = '';

      nextTick(() => {
        const nextInput = numberInputs.value[index - 1];
        if (nextInput) {
          nextInput.focus();
        }
      });
    }
  };

  const handlePaste = (event: ClipboardEvent) => {
    event.preventDefault();

    const pastedText = event.clipboardData?.getData('text').slice(0, 6);

    if (!pastedText || !/^\d+$/.test(pastedText)) {
      return;
    }

    for (let i = 0; i < pastedText.length; i++) {
      code.value[i] = pastedText[i];
    }

    const inputIndex = Math.min(code.value.length - 1, pastedText.length);
    numberInputs.value[inputIndex].focus();
  };

  const inputError = ref(Array(6).fill(false));

  const handleInput = (event: InputEvent, index: number) => {
    const inputTarget = event.target as HTMLInputElement;

    if (inputTarget?.value.length == 2) {
      // Не обновляем инпут если в нем уже есть цифра
      inputTarget.value = inputTarget.value.replace(event.data ?? '', '');
      return;
    }

    inputError.value[index] = false;
    const inputValue = event.data ?? '';

    if (!/^\d+$/.test(inputValue)) {
      inputTarget.value = '';
      inputError.value[index] = true;

      setTimeout(() => {
        inputError.value[index] = false;
      }, 1000);

      return;
    }

    code.value[index] = inputValue;
    inputTarget.value = inputValue;

    if (index < code.value.length - 1) {
      nextTick(() => {
        const nextInput = numberInputs.value[index + 1];
        if (nextInput) {
          nextInput.focus();
        }
      });
    }
  };

  // Modal
  const handleCloseModal = () => {
    sendAnalytics('Авторизация_попап_клик_крестик');
    emits('close');
  };
</script>

<style lang="scss" scoped>
  @use './auth.scss' as *;
  @use '@/assets/scss/loader.scss' as *;

  .header {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .subtitle {
    font-family: $mainFont;
    @include normalText;
  }

  .content {
    margin-top: 32px;

    @include tablet {
      margin-top: 40px;
    }
  }

  .highlight {
    color: $secondary;
  }

  .code-input {
    background-color: white;
    display: flex;
    gap: 8px;
  }

  .digit-input {
    width: 54px;
    height: 54px;
    text-align: center;
    border: 1px solid #e7e7e7;
    border-radius: 2px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }

    @include extraBigTextMobile;

    @include tablet {
      width: 66px;
      height: 56px;
      @include extraBigText;
    }

    @include hover {
      border-color: #969698;
    }

    &:focus-within {
      outline: none;
      border-color: $secondary;
    }

    &--error {
      border-color: #fd4b4b;
    }
  }

  .submit-button {
    margin-top: 24px;
    margin-bottom: 8px;
    @include buttonStyle;
  }

  .resend-button {
    width: fit-content;
    margin: auto;

    @include textButtonStyle;

    &--success[disabled] {
      color: #65b846;

      @include hover {
        &:not(:disabled) {
          color: #656565;
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .error {
    @include tinyText;

    margin-top: 8px;
    color: #fd4b4b;
    font-family: $mainFont;
  }
</style>
