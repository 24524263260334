<template>
  <div class="auth-warning">
    <img
      class="auth-warning-icon"
      src="~assets/images/info.svg"
      alt=""
    />
    <div class="auth-warning__content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
  @use './auth.scss' as *;

  .auth-warning {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    padding: 16px 12px;
    border-radius: 2px;
    gap: 12px;
    background-color: #fff3d4;
    color: #5c5c5c;
    margin: 12px auto 24px;

    @include fontNormalText(true);

    @include mobile {
      margin: 24px auto 24px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 12px;
      color: #5c5c5c;
    }
  }
</style>
