<script setup lang="ts">
  import { useModal } from '@devhacker/shared/plugins/modal/useModal';
  import ScrollContainer from '~/components/lh-ui/ScrollContainer/ScrollContainer.vue';

  defineProps({
    title: {
      type: String,
      default: '',
    },
  });

  const emits = defineEmits(['close']);

  const handleClose = () => {
    useModal().close();
    emits('close');
  };
</script>

<template>
  <ScrollContainer class="auth-modal">
    <button
      class="close-button"
      @click.stop="handleClose"
    >
      <img src="~assets/images/close.svg" />
    </button>

    <div class="title">{{ title }}</div>

    <slot />
  </ScrollContainer>
</template>

<style lang="scss" scoped>
  .auth-modal {
    margin: auto;
    padding: 48px 16px;
    min-height: 100vh;
    max-height: 100vh;
    background: white;

    @include tablet {
      width: 520px;
      min-height: 0;
      max-height: 90vh;
      padding: 56px 40px;
      position: relative;
      border-radius: 8px;
    }
  }

  .title {
    font-weight: 600;
    font-family: $secondFont;
    margin-bottom: 12px;

    @include H2HeadlineText;

    @include tablet {
      @include H1HeadlineTextMobile;
    }
  }

  .close-button {
    position: absolute;
    width: 40px;
    height: 40px;
    padding: 8px;
    top: 8px;
    right: 8px;
    cursor: pointer;
    opacity: 0.9;
    border: none;
    background: unset;

    @include hover {
      opacity: 1;
    }

    @include tablet {
      padding: 0;
      margin: 8px;
      width: 24px;
      height: 24px;
    }

    svg {
      display: block;

      path {
        fill: #969698;
      }
    }
  }
</style>
