<script setup lang="ts">
  import { ref } from 'vue';

  import TextInput from '~/components/TextInput/TextInput.vue';
  import AuthModalContainer from '~/components/Auth/AuthModalContainer.vue';
  import { useModal } from '@devhacker/shared/plugins/modal/useModal';

  const props = defineProps({
    userId: {
      type: Number,
      required: true,
    },

    name: {
      type: String,
      default: '',
    },

    email: {
      type: String,
      default: '',
    },
  });

  const emits = defineEmits(['close']);

  // analytic
  const sendAnalytics = (eventName: string, filledFields?: string) => {
    useNuxtApp().$sendYandexMetrika({
      level1: eventName,
      level4: filledFields,
    });
  };

  onMounted(() => {
    sendAnalytics('попап_после_авторизации_показ');
  });

  const nameUpdate = ref<string>(props.email ? '' : props.name);
  const emailUpdate = ref<string>(props.email);

  const isFormValid = computed(() => {
    if (!nameUpdate.value.trim()) return false;
    if (!props.email && !emailUpdate.value.trim()) return false;
    return true;
  });

  const handleSubmit = () => {
    const payload: { display_name: string; email?: string } = {
      display_name: nameUpdate.value,
    };

    if (!props.email && emailUpdate.value) {
      payload.email = emailUpdate.value;
    }

    sendAnalytics('попап_после_авторизации_клик_сохранить', payload.email ? 'ник_email' : 'ник');

    useNuxtApp().$authApi.patchUser(props.userId, payload);
    useModal().close();
  };

  const handleCloseModal = () => {
    sendAnalytics('попап_после_авторизации_закрытие_попапа');
    emits('close');
  };
</script>

<template>
  <AuthModalContainer
    class="profile-setup"
    title="Добавьте свои данные"
    @close="handleCloseModal"
  >
    <div class="profile-setup__form">
      <label class="profile-setup__form-input-wrapper">
        Ник*
        <TextInput
          id="nameInput"
          v-model="nameUpdate"
          placeholder="Укажите реальное или вымышленное имя"
        />
      </label>

      <label
        v-if="!props.email"
        class="profile-setup__form-input-wrapper"
      >
        Email*
        <TextInput
          id="emailInput"
          v-model="emailUpdate"
          placeholder="Введите почту"
        />
      </label>
    </div>

    <button
      class="profile-setup__submit"
      :disabled="!isFormValid"
      @click="handleSubmit"
    >
      Сохранить
    </button>
  </AuthModalContainer>
</template>

<style lang="scss" scoped>
  @use './auth.scss' as *;

  .profile-setup {
    &__form {
      display: flex;
      flex-direction: column;
      margin-top: 32px;

      @include tablet {
        margin-top: 40px;
      }

      &-input {
        &-wrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-bottom: 16px;

          font-family: $secondFont;
          font-weight: 600;

          @include normalText;

          &:nth-child(2) {
            margin-bottom: 24px;
          }
        }
      }
    }

    &__submit {
      @include buttonStyle;
    }
  }
</style>
