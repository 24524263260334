<template>
  <AuthModalContainer
    class="auth-window"
    :title="titleText"
    @close="closeModal"
  >
    <div class="auth-window__subtitle">
      Вы сможете общаться в комментариях и добавлять материалы в избранное.
    </div>

    <AuthWarning v-if="isEmailRestricted">
      <div>
        Вы превысили количество ввода или отправки кода. Попробуйте снова через {{ timeLeftText }}
      </div>

      <div>
        Если у вас возникли вопросы, то напишите нам на почту
        <a href="mailto:help@lifehacker.ru">help@lifehacker.ru</a>
      </div>
    </AuthWarning>

    <AuthWarning v-else-if="isUnknownError">
      Не удалось войти в аккаунт. Попробуйте снова.
    </AuthWarning>

    <form
      class="auth-window__form"
      @submit.prevent="requestCode"
    >
      <TextInput
        id="auth-email"
        ref="emailInput"
        v-model="email"
        placeholder="Email"
        @click="handleClickEmailInput"
      />

      <button
        :disabled="isEmailButtonDisabled"
        class="auth-window__form-button"
        type="submit"
      >
        Продолжить
      </button>

      <div class="auth-window__form-switch">
        {{ alternativeText }}
        <button
          class="auth-window__form-switch-button"
          @click="changeMode"
        >
          {{ modeButtonText }}
        </button>
      </div>
    </form>

    <div class="auth-window__separator">
      <span class="auth-window__separator-text">Другие способы </span>
    </div>

    <AuthWarning v-if="isCountryRestricted">
      Используйте для регистрации один из российских сервисов.
    </AuthWarning>

    <div class="auth-window__providers-wrapper">
      <ProviderButtons
        :providers="ruMethods"
        @click="handleProviderClick"
      />

      <ProviderButtons
        v-if="(mode === 'signin' && !isCountryRestricted) || !indexStore.authMethodsRestricted"
        :providers="foreignMethods"
        @click="handleProviderClick"
      />
    </div>

    <div class="auth-window__disclaimer">
      Нажимая на кнопку «Продолжить» или на кнопки сервисов, вы подтверждаете, что полностью
      принимаете условия
      <a
        href="/terms/"
        target="blank1"
        >соглашения</a
      >
      и ознакомились с
      <a
        href="/privacy/"
        target="blank2"
        >политикой обработки персональных данных</a
      >
    </div>
  </AuthModalContainer>
</template>

<script setup lang="ts">
  import { useIndexStore } from '~/store';

  import TextInput from '~/components/TextInput/TextInput.vue';
  import AuthCode from '~/components/Auth/AuthCode.vue';
  import AuthWarning from '~/components/Auth/AuthWarning.vue';
  import AuthModalContainer from '~/components/Auth/AuthModalContainer.vue';
  import ProviderButtons from '~/components/Auth/ProviderButtons.vue';
  import { useModal } from '@devhacker/shared/plugins/modal/useModal';
  import type { AuthProvidersName, AuthProvider } from './types';
  import type { RequestCodeData, RequestCodeResponse } from '~/types/api/auth';
  import { pluralRu } from '@devhacker/shared/utils/pluralRu';
  import ProfileSetup from './ProfileSetup.vue';

  const RESTRICTED_COUNTRY_ERROR_CODE = 451;
  const LIMIT_REACHED_CODE = 429;

  const emits = defineEmits(['close']);

  const indexStore = useIndexStore();

  const props = defineProps({
    eventElement: {
      required: true,
      type: String as PropType<string>,
    },

    loginCallback: {
      type: Function as PropType<() => void>,
      default: undefined,
    },
  });

  // Auth methods
  const methodToAnalytcsName: Record<AuthProvidersName, string> = {
    google: 'Гугл',
    vkontakte: 'Вконтакте',
    apple: 'Эппл',
    facebook: 'ФБ',
    mailru: 'Mail.ru',
    yandex: 'Яндекс Почта',
  };

  const ruMethods: AuthProvider[] = [
    {
      method: 'yandex',
      title: 'Яндекс',
    },
    {
      method: 'vkontakte',
      title: 'ВКонтакте',
    },
    {
      method: 'mailru',
      title: 'Mail.Ru',
    },
  ];

  const foreignMethods: AuthProvider[] = [
    {
      method: 'google',
      title: 'Google',
    },

    // {
    //   method: 'apple',
    //   title: 'Apple',
    // },
  ];

  // Method restriction and after login
  const isCountryRestricted = ref(false);

  onMounted(() => {
    useEventListener('message', ({ data }) => {
      try {
        // Проверяем что приходит объект с statusCode ошибки страны.
        if (data?.statusCode === RESTRICTED_COUNTRY_ERROR_CODE) {
          isCountryRestricted.value = true;
        }
      } catch (e) {
        console.error(e);
      }
    });

    indexStore.afterLoginAction = () => {
      authMethodForAnalytics.value && sendAnalytics(authMethodForAnalytics.value, 'Авторизация');
      props.loginCallback?.();

      const user = indexStore.user;

      if (!user?.email) {
        useModal().open(ProfileSetup, {
          id: user?.integer_id,
          email: user?.email,
          name: user?.display_name,
        });
      } else {
        closeModal();
      }
    };
  });

  // Mode
  const mode = ref<'signup' | 'signin'>('signin');

  watch(mode, () => {
    email.value = '';

    nextTick(() => {
      emailInput.value?.focusField();
    });
  });

  const changeMode = () => {
    mode.value = mode.value === 'signin' ? 'signup' : 'signin';
  };

  const titleText = computed(() => {
    return mode.value === 'signin' ? 'Войдите' : 'Зарегистрируйтесь';
  });

  const modeButtonText = computed(() => {
    return mode.value === 'signin' ? 'Зарегистрироваться' : 'Войдите';
  });

  const alternativeText = computed(() => {
    return mode.value === 'signin' ? 'Нет аккаунта?' : 'Уже есть аккаунт?';
  });

  //Analytics
  const authMethodForAnalytics = ref<AuthProvidersName | undefined>();

  const sendAnalytics = (authProvider: AuthProvidersName, eventName: string) => {
    useNuxtApp().$sendYandexMetrika({
      level1: eventName,
      level6: methodToAnalytcsName[authProvider],
      level5: props.eventElement,
      level8: 'Авторизация',
    });
  };

  const sendNewFormatAnalytics = (eventName: string) => {
    useNuxtApp().$sendYandexMetrika({
      level1: eventName,
    });
  };

  const handleProviderClick = (authProvider: AuthProvidersName) => {
    sendAnalytics(authProvider, 'Попытка авторизации');
    authMethodForAnalytics.value = authProvider;
  };

  // Modal
  const closeModal = () => {
    useNuxtApp().$sendYandexMetrika({ level1: 'Авторизация_попап_клик_крестик' });
    // Удаляем действие после авторизации
    indexStore.afterLoginAction = undefined;
    emits('close');
  };

  // Email field
  const emailInput = ref();
  const email = ref('');

  const handleClickEmailInput = () => {
    sendNewFormatAnalytics('Авторизация_попап_клик_email');
  };

  watch(email, () => {
    isEmailRestricted.value = false;
  });

  const isEmailButtonDisabled = computed(() => {
    if (codeRequestLoading.value) return true;
    if (isEmailRestricted.value) return true;
    if (!email.value.trim()) return true;

    const splitEmail = email.value.split('@');
    if (splitEmail.length !== 2) return true;
    if (!splitEmail[1].includes('.')) return true;

    return false;
  });

  // Code request
  const codeRequestLoading = ref(false);
  const hoursLeft = ref<number>(24);
  const minutesLeft = ref<number>(60);
  const isEmailRestricted = ref(false);
  const isUnknownError = ref(false);

  const timeLeftText = computed(() => {
    const hoursLeftText = `${hoursLeft.value} ${pluralRu(hoursLeft.value, 'час', 'часа', 'часов')}`;
    const minutesLeftText = ` ${minutesLeft.value} ${pluralRu(minutesLeft.value, 'минута', 'минуты', 'минут')}`;

    return `${hoursLeftText} ${minutesLeftText}`;
  });

  const requestCode = async () => {
    if (!email.value) return;

    sendNewFormatAnalytics('Авторизация_попап_продолжить');

    codeRequestLoading.value = true;
    isUnknownError.value = false;

    // TS doesnt catch email.value after check
    const { data, error } = await useAsyncData<RequestCodeResponse, RequestCodeData>(async () =>
      useNuxtApp().$authApi.requestCode(email.value ?? ''),
    );

    codeRequestLoading.value = false;

    if (error.value) {
      isEmailRestricted.value = error.value?.statusCode === LIMIT_REACHED_CODE;
      if (isEmailRestricted.value) {
        const timeLeft = error.value.data?.data?.time_left ?? 86400;
        hoursLeft.value = Math.floor(timeLeft / 3600);
        minutesLeft.value = Math.floor((timeLeft % 3600) / 60);
      } else {
        isUnknownError.value = true;
      }

      return;
    }

    if (data.value?.data.success) {
      useModal().open(
        AuthCode,
        {
          email: email.value,
        },
        {
          transition: 'modal-fade',
        },
      );
    }
  };
</script>

<style lang="scss" scoped>
  @use './auth.scss' as *;

  .auth-window {
    &__providers-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      column-gap: 12px;
      row-gap: 16px;
    }

    &__subtitle {
      font-weight: 400;
      margin-bottom: 32px;

      @include normalText;
      @include tablet {
        @include fontMediumText;
        margin-bottom: 40px;
      }
    }

    &__disclaimer {
      @include fontTextBtn;

      & {
        margin-top: 32px;
        font-weight: 400;
        display: inline-block;
      }

      @include tablet {
        margin-top: 40px;
      }

      a {
        @include linkStyle;
      }
    }

    &__separator {
      color: #5c5c5c;
      position: relative;
      text-align: center;
      z-index: 2;
      margin: 32px 0px 24px;

      @include tablet {
        margin: 40px 0px 24px;
      }

      @include smallText;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #e7e7e7;
        z-index: -1;
      }

      &-text {
        padding: 0px 12px;
        background-color: white;
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &-button {
        position: relative;
        @include buttonStyle;
      }

      &-switch {
        &-button {
          @include textButtonStyle;
          margin-left: 4px;
        }
      }
    }
  }
</style>
