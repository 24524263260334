<template lang="pug">
  <div class="scroll-container">
    <slot />
  </div>
</template>

<script lang="ts">
  // import { type PropType } from 'vue';
  // import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

  // TODO: Check all scrollbars
  export default defineNuxtComponent({
    name: 'ScrollContainer',
    // components: {
    //   PerfectScrollbar,
    // },
    // props: {
    //   active: {
    //     type: Boolean as PropType<boolean>,
    //     default: true,
    //   },
    //   options: {
    //     type: Object as PropType<object>,
    //     default: () => ({}),
    //   },
    // },
    // watch: {
    //   active(newValue) {
    //     if (newValue) {
    //       this.updatePerfectScrollbar();
    //     }
    //   },
    // },
    // mounted(): void {
    //   this.updatePerfectScrollbar();
    // },
    // methods: {
    //   updatePerfectScrollbar() {
    //     // Если элемент изначально не отображается, то скроллбар не может рассчитать свои размеры.
    //     // Поэтому при появлении элемента на экране нужно вызвать метод update.
    //     // См issue https://github.com/mdbootstrap/perfect-scrollbar/issues/476
    //     // и документацию библиотеки perfect-scrollbar
    //     try {
    //       // @ts-ignore
    //       this.$refs.perfectScrollbar.update();
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   },
    // },
  });
</script>

<!-- <style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"></style> -->
<!-- ; -->
<!-- <style lang="scss" src="./ScrollContainer.scss"></style> -->

<style lang="scss" scoped>
  .scroll-container {
    @include custom-scrollbar;
  }
</style>
