<script setup lang="ts">
  import type { PropType } from 'vue';
  import { AUTH_API_URL } from '~/constants/config';
  import googleIconPath from '@/assets/images/auth/google.svg';
  import vkontakteIconPath from '@/assets/images/auth/vkontakte.svg';
  import appleIconPath from '@/assets/images/auth/apple.svg';
  import yandexIconPath from '@/assets/images/auth/yandex.svg';
  import mailruIconPath from '@/assets/images/auth/mailru.svg';
  import type { AuthProvider, AuthProvidersName } from './types';

  const emits = defineEmits(['click']);

  defineProps({
    providers: {
      type: Array as PropType<AuthProvider[]>,
      required: true,
    },
  });

  const getMethodUrl = (method: AuthProvidersName) => {
    switch (method) {
      case 'google':
        return googleIconPath;
      case 'vkontakte':
        return vkontakteIconPath;
      case 'apple':
        return appleIconPath;
      case 'yandex':
        return yandexIconPath;
      case 'mailru':
        return mailruIconPath;
    }
  };

  const openWindow = (method: AuthProvidersName) => {
    emits('click', method);

    window.open(
      `${AUTH_API_URL}/id/auth/${method}?display=popup&x-auth-app=web`,
      'popUpWindow',
      'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes',
    );
  };
</script>

<template>
  <div class="auth-providers-wrapper">
    <button
      v-for="(button, index) in providers"
      :key="index"
      class="auth-provider-button"
      :class="`auth-provider-button--${button.method}`"
      @click.stop="openWindow(button.method)"
    >
      <div class="auth-provider-button-logo-wrapper">
        <div
          class="auth-provider-button-logo"
          :class="`auth-provider-button-logo--${button.method}`"
        >
          <img :src="getMethodUrl(button.method)" />
        </div>
      </div>
      <span class="auth-provider-button-label">{{ button.title }}</span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
  $auth-facebook: #0866ff;
  $auth-google: #4285f4;
  $auth-vk: #0077ff;
  $auth-twitter: #54acee;
  $auth-yandex: #ff0000;
  $auth-mail: #005ff9;
  $auth-apple: #000000;

  .auth-providers-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    column-gap: 12px;
    row-gap: 16px;

    &-subtitle {
      color: #5c5c5c;
      @include fontSmallText;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .auth-provider-button {
    @include fontTextBtn;

    & {
      font-weight: 400;
      flex: 1;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      color: black;
      cursor: pointer;
      background-color: white;
      border: 2px solid #e7e7e7;
    }

    @include tablet {
      flex: 0 0 100%;
      justify-content: flex-start;
      height: 44px;
    }

    &-label {
      display: none;

      @include tablet {
        display: block;
      }
    }

    &-logo {
      display: flex;

      &-wrapper {
        width: 44px;
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 1px;
        overflow: hidden;

        @include tablet {
          margin-right: 8px;
        }
      }
    }

    @include hover {
      color: white;
    }

    &:not(.auth-provider-button--google):not(.auth-provider-button--mailru) {
      @include hover {
        img {
          filter: brightness(0) invert(1);
        }
      }
    }

    &--facebook {
      @include hover {
        background-color: $auth-facebook;
        border-color: transparent;
      }
    }

    &--google {
      @include hover {
        background-color: $auth-google;
        border-color: transparent;

        .auth-provider-button-logo-wrapper {
          background: white;
        }
      }
    }

    &--vkontakte {
      @include hover {
        background-color: $auth-vk;
        border-color: transparent;
      }
    }

    &--twitter {
      @include hover {
        background-color: $auth-twitter;
      }
    }

    &--yandex {
      @include hover {
        background-color: $auth-yandex;
      }
    }

    &--mailru {
      @include hover {
        background-color: $auth-mail;
      }
    }

    &--apple {
      @include hover {
        background-color: $auth-apple;
      }
    }
  }
</style>
